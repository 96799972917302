import { productDetails } from '@shared/reflections';
import { IReviewCycle, IReviewCycleDashboardRow } from '@shared/review-cycles';
import { IUser } from '@shared/types';
import { UserMessage } from '@web/components/UserMessage';
import { Column, GrowingSpacer, Row, Spacer } from '@web/components/layout';
import { Button, Empty, Skeleton, Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import * as React from 'react';

import { AddParticipantsModal } from './AddParticipantsModal';
import { DashboardRowActionsMenu } from './DashboardRowActionsMenu';
import { SelectParticipantFilter } from './SelectParticipantFilter';
import {
  AdminManagerReflectionCell,
  AdminPeerSelectionStatusCell,
  AdminSelfReflectionCell,
  PeerFeedbackReceivedCell,
  ReportsFeedbackReceivedCell,
  ReviewsSharedCell,
} from './dashboard';
import { useAdminDashboardSearch } from './useAdminDashboardSearch';

const DEFAULT_COLUMN_WIDTH = '150px';

export const ParticipantSummaryTable: React.FC<{
  reviewCycle: IReviewCycle;
  onChange: () => void;
}> = ({ reviewCycle, onChange }) => {
  const [showAddParticipants, setShowAddParticipants] = React.useState(false);
  const {
    searchResults,
    search: reloadDashboard,
    pagination,
    filter,
    setFilter,
    clearFilter,
  } = useAdminDashboardSearch(reviewCycle.token);
  if (!searchResults) {
    return <Skeleton />;
  }
  const { results: dashboardRows } = searchResults;

  const selfReviewProduct = productDetails(
    reviewCycle.selfReflectionProductName,
  );
  const selfReviewProductName = `Self ${selfReviewProduct.lowerCase}`;
  const managerReviewProduct = productDetails(
    reviewCycle.managerReviewProductName,
  );
  const managerReviewProductName = `Manager ${managerReviewProduct.lowerCase}`;
  const handleAddParticipants = async () => {
    void reloadDashboard();
    onChange();
    setShowAddParticipants(false);
  };

  const columns: ColumnsType<IReviewCycleDashboardRow> = [
    {
      title: 'Participant',
      dataIndex: 'participant',
      key: 'participant',
      render: (participant: IUser) => {
        return (
          <UserMessage user={participant} style={{ whiteSpace: 'nowrap' }} />
        );
      },
    },
    reviewCycle.peerReviewCycleEnabled
      ? {
          title: 'Peers',
          key: 'peers',
          align: 'center',
          width: DEFAULT_COLUMN_WIDTH,
          render: (_, row) => (
            <AdminPeerSelectionStatusCell
              row={row}
              reviewCycle={reviewCycle}
              isAdminView
            />
          ),
        }
      : undefined,
    reviewCycle.peerReviewCycleEnabled
      ? {
          title: 'Feedback received',
          key: 'feedbackReceived',
          align: 'center',
          width: 150,
          render: (_, row) => (
            <PeerFeedbackReceivedCell
              row={row}
              reviewCycle={reviewCycle}
              isAdminView
            />
          ),
        }
      : undefined,
    reviewCycle.upwardReviewCycleEnabled
      ? {
          title: 'Upward feedback',
          key: 'upwardFeedback',
          align: 'center',
          width: 150,
          render: (_, row) => (
            <ReportsFeedbackReceivedCell
              row={row}
              reviewCycle={reviewCycle}
              isAdminView
            />
          ),
        }
      : undefined,
    reviewCycle.selfReflectionCycleEnabled
      ? {
          title: selfReviewProductName,
          key: 'selfReflection',
          align: 'center',
          width: DEFAULT_COLUMN_WIDTH,
          render: (_, row) => (
            <AdminSelfReflectionCell
              row={row}
              reviewCycle={reviewCycle}
              isAdminView
            />
          ),
        }
      : undefined,
    reviewCycle.managerReflectionCycleEnabled
      ? {
          title: managerReviewProductName,
          key: 'managerReflection',
          align: 'center',
          width: DEFAULT_COLUMN_WIDTH,
          render: (_, row) => (
            <AdminManagerReflectionCell
              row={row}
              reviewCycle={reviewCycle}
              isAdminView
            />
          ),
        }
      : undefined,
    reviewCycle.peerReviewCycleEnabled ||
    reviewCycle.managerReflectionCycleEnabled
      ? {
          title: 'Shared',
          key: 'feedbackReleased',
          align: 'center',
          width: DEFAULT_COLUMN_WIDTH,
          render: (_, row) => (
            <ReviewsSharedCell
              row={row}
              reviewCycle={reviewCycle}
              isAdminView
            />
          ),
        }
      : undefined,
    {
      title: 'Actions',
      key: 'actions',
      align: 'center',
      width: DEFAULT_COLUMN_WIDTH,
      render: (_, row) => {
        return (
          <DashboardRowActionsMenu
            reviewCycle={reviewCycle}
            row={row}
            onChange={() => {
              void reloadDashboard();
              onChange();
            }}
          />
        );
      },
    },
  ].filter((item) => !!item) as ColumnsType<IReviewCycleDashboardRow>;

  return (
    <Column>
      <Row>
        <SelectParticipantFilter
          reviewCycle={reviewCycle}
          filter={filter}
          setFilter={setFilter}
          clearFilter={clearFilter}
        />
        <GrowingSpacer />
        {!reviewCycle.closedDate && (
          <Button
            onClick={() => {
              setShowAddParticipants(true);
            }}
          >
            Add participants
          </Button>
        )}
      </Row>
      <Spacer size={16} />
      <Table<IReviewCycleDashboardRow>
        columns={columns}
        dataSource={dashboardRows}
        pagination={pagination}
        locale={{
          emptyText: <Empty description="No participants found" />,
        }}
        rowKey="token"
        tableLayout="auto"
        sticky={true}
      />
      {showAddParticipants && (
        <AddParticipantsModal
          reviewCycleToken={reviewCycle.token}
          onSave={handleAddParticipants}
          onCancel={() => {
            setShowAddParticipants(false);
          }}
        />
      )}
    </Column>
  );
};
