import { IImpact } from './impacts';
import { IQuestionSet, QuestionSetToken } from './questionSets';
import { QuestionToken } from './questions';
import { EntityReactionSummaryMap } from './reactions';
import {
  IReflection,
  IReflectionResponse,
  ProductName,
  ReflectionToken,
  UpdateReflectionRequest,
  UpdateReflectionResponse,
} from './reflections';
import {
  IAutoScheduledEvent,
  IAutoScheduledEventUpdate,
  ScheduledEventToken,
} from './scheduled_event';
import {
  ITasksResponse,
  ITeam,
  IUser,
  OrganizationToken,
  UserMap,
  UserMapItem,
  UserToken,
} from './types';

export type ReviewCycleToken = `rc_${string}`;
export interface IReviewCycle {
  token: ReviewCycleToken;
  name: string;
  organizationToken: OrganizationToken;
  participants?: IUser[];
  startedDate?: Date;
  peerReviewCycleEnabled: boolean;
  peerReviewTemplateToken?: QuestionSetToken;
  peerReviewCycleStarted?: Date;
  peerReviewFeedbackRequested?: Date;
  selfReflectionQuestionSetToken?: QuestionSetToken;
  selfReflectionCycleEnabled: boolean;
  selfReflectionCycleStarted?: Date;
  selfReflectionProductName: ProductName;
  managerReflectionCycleStarted?: Date;
  peerSelectionStartDate?: Date;
  peerSelectionEndDate?: Date;
  peerApprovalStartDate?: Date;
  peerApprovalEndDate?: Date;
  peerFeedbackStartDate?: Date;
  peerFeedbackEndDate?: Date;
  selfReviewStartDate?: Date;
  selfReviewEndDate?: Date;
  managerReflectionQuestionSetToken?: QuestionSetToken;
  managerReviewProductName: ProductName;
  managerReflectionCycleEnabled: boolean;
  managerReviewStartDate?: Date;
  managerReviewEndDate?: Date;
  sharedByDate?: Date;

  /**
   * =======================================
   *  Upwards review configuration
   * =======================================
   */

  /**
   * Determines whether the upwards review module is enabled
   * for this cycle
   */
  upwardReviewCycleEnabled: boolean;
  /**
   * If enabled, this identifies the question set to use for
   * upward feedback during this cycle
   */
  upwardReviewQuestionSetToken?: QuestionSetToken;
  /**
   * The date when the upwards review module started
   */
  upwardReviewCycleStarted?: Date;
  /**
   * The date when the upwards review feedback was initially requested.
   * Participants added later to the cycle will get feedback requested
   * as their reports are approved by their manager.
   */
  upwardReviewFeedbackRequested?: Date;
  /**
   * Upwards review schedule for when report approvals should start
   */
  reportApprovalStartDate?: Date;
  /**
   * Upwards review schedule for when report approvals should end
   */
  reportApprovalEndDate?: Date;
  /**
   * Upwards review schedule for when feedback requests should be sent
   */
  upwardFeedbackStartDate?: Date;
  /**
   * Upwards review schedule for feedback should be submitted by
   */
  upwardFeedbackEndDate?: Date;

  events?: IReviewCycleEvent[];
  batchEvents?: IReviewCycleBatchEvent[];
  notificationHour?: number;
  closedDate?: Date;
  createdDate: Date;
  updatedDate: Date;
  deletedDate?: Date;
}

export type PeerReviewToken = `pr_${string}`;
export interface IPeerReview {
  token: PeerReviewToken;
  user?: IUser;
  selectedPeers?: IUser[];
  approvedPeers?: IUser[];
  reviewCycleToken?: ReviewCycleToken;
  reviewCycle?: IReviewCycle;
  organizationToken?: OrganizationToken;
  createdDate: Date;
  updatedDate: Date;
  deletedDate?: Date;
  requestPeersDate?: Date;
  peersSelectedDate?: Date;
  peersApprovedDate?: Date;
  feedbackRequestedDate?: Date;
  feedbackReleasedDate?: Date;
}

export type UpwardReviewToken = `ur_${string}`;
export interface IUpwardReview {
  token: UpwardReviewToken;
  user: IUser;
  manager: IUser;
  approvedReports: IUser[];
  reviewCycleToken?: ReviewCycleToken;
  reviewCycle?: IReviewCycle;
  organizationToken?: OrganizationToken;
  createdDate: Date;
  updatedDate: Date;
  deletedDate?: Date;
  reportsApprovedDate?: Date;
  feedbackRequestedDate?: Date;
  feedbackReleasedDate?: Date;
}

export type ReviewCycleEventToken = `rce_${string}`;

export class IReviewCycleEvent {
  token: ReviewCycleEventToken;
  organizationToken: OrganizationToken;
  type: ReviewCycleEventType;
  eventToken: ScheduledEventToken;
  event: IAutoScheduledEvent;
}

export type ReviewCycleBatchEventToken = `rcb_${string}`;
export class IReviewCycleBatchEvent {
  token: ReviewCycleBatchEventToken;
  organizationToken: OrganizationToken;
  reviewCycleToken: ReviewCycleToken;
  events: ReviewCycleEventType[];
  scheduledEvent: IAutoScheduledEvent;
}

export enum DashboardRowFilter {
  MISSING_SELECTED_PEERS = 'MISSING_SELECTED_PEERS',
  MISSING_APPROVED_PEERS = 'MISSING_APPROVED_PEERS',
  MISSING_FEEDBACK = 'MISSING_FEEDBACK',
  MISSING_SELF_REVIEW = 'MISSING_SELF_REVIEW',
  MISSING_MANAGER_REVIEW = 'MISSING_MANAGER_REVIEW',
}

export interface IReviewCycleTeamSummary {
  team?: ITeam;
  managerReflectionCompleted?: number;
  managerReflectionSent?: number;
  peersSelected?: number;
  peersApproved?: number;
  selfReflectionCompleted?: number;
  totalParticipants?: number;
  feedbackReceived?: number;
}

export const emptyTeamSummary = (team: ITeam) => {
  const summary: IReviewCycleTeamSummary = {
    managerReflectionCompleted: 0,
    managerReflectionSent: 0,
    peersSelected: 0,
    peersApproved: 0,
    feedbackReceived: 0,
    selfReflectionCompleted: 0,
    team,
    totalParticipants: 0,
  };
  return summary;
};

export interface IPeerReviewRowInfo {
  peerReviewToken: `pr_${string}`;
  peersSummary: string;
  peerRequestsSent: number;
  peersFeedbackSummary: string;
  peerRequestsSummary: string;
  peerRequestsReceived: number;
  peerFeedbackGiven: number;
  peerFeedbackReceived: number;
  peersSelected: number;
  peersApproved: number;
  peerSharedCount: number;
  feedbackReleasedDate: Date | string;
}

export interface IUpwardFeedbacRowInfo {
  upwardReviewToken: UpwardReviewToken;
  upwardRequestsSent: number;
  reportsFeedbackSummary: string;
  upwardRequestsSummary: string;
  upwardRequestsReceived: number;
  upwardFeedbackGiven: number;
  upwardFeedbackReceived: number;
  approvedReports: number;
  reportsSummary: string;
  upwardSharedCount: number;
  upwardFeedbackReleasedDate: Date | string;
}

export interface IReviewCycleDashboardRow
  extends IPeerReviewRowInfo,
    IUpwardFeedbacRowInfo {
  participantToken: UserToken;
  participant: UserMapItem;
  selfReflectionSent?: boolean;
  selfReflectionCompleted?: boolean;
  selfReflectionToken?: ReflectionToken;
  managerReflectionSent?: boolean;
  managerReflectionCompleted?: boolean;
  managerReflectionToken?: ReflectionToken;
  managerReflectionAuthorToken?: UserToken;
  feedbackReleased?: boolean;
  managerReviewReleased?: boolean;
  releasedContent?: 'none' | 'partial' | 'all';
}

export interface IReviewCycleParticipantSummary {
  participant: IUser;
  isParticipant: boolean;
  peerReview?: IPeerReview;
  reviewCycle: IReviewCycle;
  selfReview?: IReflection;
  managerReview?: IReflection;
  dashboardRows?: IReviewCycleDashboardRow[];
  openTasksResponse?: ITasksResponse;
}

export interface IReviewCycleComponentStatus {
  title: string;
  text: string;
  progressPercent?: number;
  startDate?: Date;
  endDate?: Date;
}

export interface IReviewCycleStatus {
  peerReview: IReviewCycleComponentStatus;
  upwardFeedback?: IReviewCycleComponentStatus;
  selfReview: IReviewCycleComponentStatus;
  managerReview: IReviewCycleComponentStatus;
  overall?: {
    icon: string;
    message: string;
  };
}

export interface UpdateCycleReflectionRequest extends UpdateReflectionRequest {}

export interface UpdateCycleReflectionResponse
  extends UpdateReflectionResponse {}

export interface GetManagerReviewResponse {
  review: IReflection | null;
  // Recent journal entries from the receiver of the review.
  recentJournalEntries?: IImpact[];
  reactions: {
    reactionSummaryMap: EntityReactionSummaryMap;
    userMap: UserMap;
  };
  // For a given QuestionToken in the `managerReview` returns the response for
  // the 'equivalent' self review question.
  counterpartResponses: Record<QuestionToken, IReflectionResponse>;
  questionSet: IQuestionSet;
}

export interface UpdateReviewCycleNotificationsRequest {
  type: ReviewCycleEventType;
  update: IAutoScheduledEventUpdate;
}

export interface ReconcileManagerReviewsRequest {
  dryRun: boolean;
}

export interface ReconcileManagerReviewsResponse {
  changes: string[];
}

export enum ReviewCycleEventType {
  START_REVIEW_CYCLE = 'START_REVIEW_CYCLE',
  CYCLE_INTRO = 'CYCLE_INTRO',
  START_PEER_REVIEW_CYCLE = 'START_PEER_REVIEW_CYCLE',
  START_UPWARD_REVIEW_CYCLE = 'START_UPWARD_REVIEW_CYCLE',
  PEER_SELECTION_REMINDER = 'PEER_SELECTION_REMINDER',
  PEER_APPROVAL_REMINDER = 'PEER_APPROVAL_REMINDER',
  SEND_PEER_FEEDBACK_REQUESTS = 'SEND_PEER_FEEDBACK_REQUESTS',
  FEEDBACK_REQUEST_REMINDER = 'FEEDBACK_REQUEST_REMINDER',
  FEEDBACK_REQUEST_DUE_REMINDER = 'FEEDBACK_REQUEST_DUE_REMINDER',
  FEEDBACK_REQUEST_OVERDUE_REMINDER = 'FEEDBACK_REQUEST_OVERDUE_REMINDER',
  SEND_UPWARD_FEEDBACK_REQUESTS = 'SEND_UPWARD_FEEDBACK_REQUESTS',
  UPWARD_FEEDBACK_REQUEST_REMINDER = 'UPWARD_FEEDBACK_REQUEST_REMINDER',
  UPWARD_FEEDBACK_REQUEST_DUE_REMINDER = 'UPWARD_FEEDBACK_REQUEST_DUE_REMINDER',
  UPWARD_FEEDBACK_REQUEST_OVERDUE_REMINDER = 'UPWARD_FEEDBACK_REQUEST_OVERDUE_REMINDER',
  START_SELF_REVIEWS = 'START_SELF_REVIEWS',
  SELF_REVIEW_REMINDER = 'SELF_REVIEW_REMINDER',
  SELF_REVIEW_DUE_REMINDER = 'SELF_REVIEW_DUE_REMINDER',
  SELF_REVIEW_OVERDUE_REMINDER = 'SELF_REVIEW_OVERDUE_REMINDER',
  START_MANAGER_REVIEWS = 'START_MANAGER_REVIEWS',
  MANAGER_REVIEW_REMINDER = 'MANAGER_REVIEW_REMINDER',
  MANAGER_REVIEW_DUE_REMINDER = 'MANAGER_REVIEW_DUE_REMINDER',
  MANAGER_REVIEW_OVERDUE_REMINDER = 'MANAGER_REVIEW_OVERDUE_REMINDER',
  RELEASE_REMINDER = 'RELEASE_REMINDER',
}

export const editManagerReviewUrl = (reflection: IReflection): string => {
  return `/cycles/${reflection.reviewCycleToken}/users/${reflection.receiverToken}`;
};

export const isReleasable = (reviewCycle: IReviewCycle): boolean =>
  (reviewCycle.peerReviewCycleEnabled &&
    !!reviewCycle.peerReviewFeedbackRequested) ||
  (reviewCycle.managerReflectionCycleEnabled &&
    !!reviewCycle.managerReflectionCycleStarted);

export interface IExportResponseRow {
  form: string;
  participant: string;
  author: string;
  questionNum: string | number;
  questionText: string;
  responseText: string;
  responseValue?: string | number;
}
